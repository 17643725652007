import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { EMPTY_TARIFF_MAPPING_ENTITY, TariffMappingEntity } from "domain/entity/TariffMapping/TariffMappingEntity";
import { ConsortiumRepository } from "domain/repository/ChargeDetailMaintenance/ConsortiumRepo";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CntrTypeGroupRepository } from "domain/repository/CntrTypeGroup/CntrTypeGroupRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { EquipmentCodeIndMappingRepository } from "domain/repository/EquipmentCodeIndMapping/EquipmentCodeIndMappingRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { OpsLineRepository } from "domain/repository/OpsLine/OpsLineRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { ShiftRepository } from "domain/repository/Shift/ShiftRepo";
import { SpecialHandlingIndMappingRepository } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepo";
import { SpecialIndRepository } from "domain/repository/SpecialInd/SpecialIndRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffMappingRepository } from "domain/repository/TariffMapping/TariffMappingRepo";
import _ from "lodash";
import { tariffMappingCSVColumnMapping, tariffMappingCSVNumberColumnMapping } from "presentation/constant/TariffMapping/TariffMappingConstant";
import { DropdownProps } from "presentation/model/DropdownProps";
import { TariffMappingModel } from "presentation/model/TariffMapping/TariffMappingModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface TariffMappingVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<TariffMappingModel>> | ((value: SetStateAction<TariffMappingModel>) => void),
    ],
    tariffMappingRepo: TariffMappingRepository,
    chargeTypeRepo: ChargeTypeRepository,
    specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepository,
    opsLineRepo: OpsLineRepository,
    masterDataRepo: MasterDataRepository,
    companyRepo: CompanyRepository,
    parameterDetailRepo: ParameterDetailRepository,
    consortiumRepo: ConsortiumRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    cntrTypeGroupRepo: CntrTypeGroupRepository,
    specialIndRepo: SpecialIndRepository,
    equipmentCodeIndMappingRepo: EquipmentCodeIndMappingRepository,
    shiftRepo: ShiftRepository,
}

export const TariffMappingVM = ({ dispatch, tariffMappingRepo, chargeTypeRepo, specialhandlingIndMappingRepo,
    opsLineRepo, masterDataRepo, companyRepo, parameterDetailRepo, consortiumRepo, standardTariffCodeRepo, cntrTypeGroupRepo,
    specialIndRepo, equipmentCodeIndMappingRepo, shiftRepo
}: TariffMappingVMProps) => {
    const [tariffMappingDispatch] = dispatch;

    const loadDropdownOption = async () => {

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypeList = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let newChargeTypes = newChargeTypeList.filter(entity => entity.chargeCategory === 'GEN' || entity.chargeCategory === 'SYS');
                let chargeTypeDropdownOption: DropdownProps[] = [];
                let subChargeTypeDropdownOption: { [key: string]: DropdownProps[] } = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOption.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOption.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOption[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOption[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOption[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOption,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOption
                    }
                }))
            }
        )

        await specialhandlingIndMappingRepo.getAllSpecialHandlingIndMappings().then(
            specialHandlingIndList => {
                const specialHandlingindDropdownOption = specialHandlingIndList?.map((specialHandlingInd) => ({
                    dropdownLabel: specialHandlingInd.specialHandlingInd,
                    tagLabel: specialHandlingInd.specialHandlingInd,
                    value: specialHandlingInd.specialHandlingInd,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        specHandingIndDropdownOptions: specialHandlingindDropdownOption,
                    }
                }))
            }
        )
        await opsLineRepo.getAllOpsLines().then(
            opsLines => {
                const opsLinesDropdownOptions = opsLines?.map((opsLineEntity) => ({
                    dropdownLabel: opsLineEntity.opsLine,
                    tagLabel: opsLineEntity.opsLine,
                    value: opsLineEntity.opsLine,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        opsLineDropdownOptions: opsLinesDropdownOptions
                    }
                }))
            }
        )


        await masterDataRepo.getMasterDataByKey(MasterDataType.MARSHAL).then(
            marshalEntities => {
                const marshalCodeDropdownOptions = marshalEntities?.map((marshal) => ({
                    dropdownLabel: marshal.code,
                    tagLabel: marshal.code,
                    value: marshal.code,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        marshalCodeDropdownOptions: marshalCodeDropdownOptions,
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.MODALITY).then(
            modalityEntities => {
                const modalityCodeDropdownOptions = modalityEntities?.map((modality) => ({
                    dropdownLabel: modality.code,
                    tagLabel: modality.code,
                    value: modality.code,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        modalityDropdownOptions: modalityCodeDropdownOptions,
                    }
                }))
            }
        )


        await masterDataRepo.getMasterDataByKey(MasterDataType.SERVICE).then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode) => ({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeDropdownOptions: serviceCodeDropdownOptions
                    }
                }))
            }
        );
        await masterDataRepo.getMasterDataByKey(MasterDataType.CHARGE_IND).then(
            chargeInds => {
                const chargeIndDropdownOptions = chargeInds?.map((chargeInd) => ({
                    dropdownLabel: chargeInd.code,
                    tagLabel: chargeInd.code,
                    value: chargeInd.code,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeIndDropdownOptions: chargeIndDropdownOptions
                    }
                }))
            }
        );


        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOption = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOption = _.orderBy(companyCodeDropdownOption, "dropdownLabel");

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: companyCodeDropdownOption,
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("MARSHALLING_TYPE").then(
            marshallingTypeList => {
                const marshallingTypeDropdownOption = marshallingTypeList?.map((marshallingType) => ({
                    dropdownLabel: marshallingType.parameterDtlCode,
                    tagLabel: marshallingType.parameterDtlCode,
                    value: marshallingType.parameterDtlCode,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        marshallingTypeDropdownOptions: marshallingTypeDropdownOption
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_SIZE").then(
            cntrSizeList => {
                const sizeDropdownOption = cntrSizeList?.map((cntrSize) => ({
                    dropdownLabel: cntrSize.parameterDtlCode,
                    tagLabel: cntrSize.parameterDtlCode,
                    value: cntrSize.parameterDtlCode,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        cntrSizeDropdownOptions: sizeDropdownOption
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_TYPE").then(
            cntrTypeList => {
                const typeDropdownOption = cntrTypeList?.map((cntrType) => ({
                    dropdownLabel: cntrType.parameterDtlCode,
                    tagLabel: cntrType.parameterDtlCode,
                    value: cntrType.parameterDtlCode,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        cntrTypeDropdownOptions: typeDropdownOption
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("SHIPMENT_TYPE").then(
            shipmentTypeList => {
                const shipmentTypeDropdownOption = shipmentTypeList?.map((shipmentType) => ({
                    dropdownLabel: shipmentType.parameterDtlCode,
                    tagLabel: shipmentType.parameterDtlCode,
                    value: shipmentType.parameterDtlCode,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        shipmentTypeDropdownOptions: shipmentTypeDropdownOption
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("TML_CODE").then(
            tmlCodeList => {
                const tmlCodeDropdownOption = tmlCodeList?.map((tmlCode) => ({
                    dropdownLabel: tmlCode.parameterDtlCode,
                    tagLabel: tmlCode.parameterDtlCode,
                    value: tmlCode.parameterDtlCode,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        terminalCodeDropdownOptions: tmlCodeDropdownOption
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("OPERATION_TYPE").then(
            operatingTypeList => {
                const operatingTypeDropdownOption = operatingTypeList?.map((operatingType) => ({
                    dropdownLabel: operatingType.parameterDtlCode,
                    tagLabel: operatingType.parameterDtlCode,
                    value: operatingType.parameterDtlCode,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operationTypeDropdownOptions: operatingTypeDropdownOption
                    }
                }))
            }
        )

        await consortiumRepo.getAllConsortiums().then(
            consortiums => {
                let consortiumCodeDropdownOptions = consortiums?.map((item) => ({
                    dropdownLabel: item.consortiumCode,
                    tagLabel: item.consortiumCode,
                    value: item.consortiumCode,
                })) ?? []
                consortiumCodeDropdownOptions = _.orderBy(consortiumCodeDropdownOptions, "dropdownLabel");

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        consortiumCodeDropdownOptions: consortiumCodeDropdownOptions,
                    }
                }))
            }
        );
        await cntrTypeGroupRepo.getCntrTypeGroupForComboBox().then(
            cntrTypeGroups => {
                let cntrTypeGroupDropdownOption = cntrTypeGroups?.map((item) => ({
                    dropdownLabel: item.cntrTypeGroup,
                    tagLabel: item.cntrTypeGroup,
                    value: item.cntrTypeGroup,
                })) ?? []
                cntrTypeGroupDropdownOption = _.orderBy(cntrTypeGroupDropdownOption, "dropdownLabel");

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        cntrTypeGroupDropdownOptions: cntrTypeGroupDropdownOption,
                    }
                }))
            }
        );

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {

                let newTariffs = _.orderBy(tariffs, ["tariffType", "tariffCode"]);
                let tariffTypeDropdownOptions: DropdownProps[] = [];
                let tariffCodeDropdownOptions: { [key: string]: DropdownProps[] } = {};
                let allTariffCodeeDropdownOptions: DropdownProps[] = [];

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t =>
                        t.value === tariff.tariffType);
                    if (!isTariffTypeExisted) {
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    const isTariffCodeExisted = allTariffCodeeDropdownOptions.find(t =>
                        t.value === tariff.tariffCode);

                    if (!isTariffCodeExisted) {
                        allTariffCodeeDropdownOptions.push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }

                    if (tariff.tariffCode) {
                        if (!tariffCodeDropdownOptions[tariff.tariffType]) {
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.HANDLING_CODE).then(
            handlingCodes => {
                const handlingCodeDropdownOptions = handlingCodes?.map((handlingCode) => ({
                    dropdownLabel: handlingCode.code,
                    tagLabel: handlingCode.code,
                    value: handlingCode.code,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        physicalHandlingCodeDropdownOptions: handlingCodeDropdownOptions
                    }
                }))
            }
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.FORWARDER_CODE).then(
            forwarderCodes => {
                const forwarderCodesDropdownOptions = forwarderCodes?.map((forwarderCode) => ({
                    dropdownLabel: forwarderCode.code,
                    tagLabel: forwarderCode.code,
                    value: forwarderCode.code,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        forwarderCodeDropdownOptions: forwarderCodesDropdownOptions,
                    }
                }))
            }
        )
        await masterDataRepo.getMasterDataByKey(MasterDataType.TS_LEG_CHG_IND).then(
            transhipmentLegChargeInds => {
                const transhipmentLegChargeIndDropdownOption = transhipmentLegChargeInds?.map((transhipmentLegChargeInd) => ({
                    dropdownLabel: transhipmentLegChargeInd.code,
                    tagLabel: transhipmentLegChargeInd.code,
                    value: transhipmentLegChargeInd.code,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        transhipmentLegChargeIndDropdownOptions: transhipmentLegChargeIndDropdownOption,
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("SERVICE_ORDER_CODE").then(
            serviceOrderCodeList => {
                const serviceOrderCodeDropdownOption = serviceOrderCodeList?.map((serviceOrderCode) => ({
                    dropdownLabel: serviceOrderCode.parameterDtlCode,
                    tagLabel: serviceOrderCode.parameterDtlCode,
                    value: serviceOrderCode.parameterDtlCode,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceOrderCodeDropdownOptions: serviceOrderCodeDropdownOption
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CARGO_TYPE").then(
            cargoTypeList => {
                const cargoTypeDropdownOption = cargoTypeList?.map((cargoType) => ({
                    dropdownLabel: cargoType.parameterDtlCode,
                    tagLabel: cargoType.parameterDtlCode,
                    value: cargoType.parameterDtlCode,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        cargoTypeDropdownOptions: cargoTypeDropdownOption
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CARGO_CODE").then(
            cargoCodeList => {
                const cargoCodeDropdownOption = cargoCodeList?.map((cargoCode) => ({
                    dropdownLabel: cargoCode.parameterDtlCode,
                    tagLabel: cargoCode.parameterDtlCode,
                    value: cargoCode.parameterDtlCode,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        cargoCodeDropdownOptions: cargoCodeDropdownOption
                    }
                }))
            }
        )
        await parameterDetailRepo.getParameterThirdDtlByParaCodeAndParaDtlCode("RAILWAY_SETTING", "SPECIAL_AREAS_TO_CHARGE_PER_CNTR").then(
            specialAreaList => {
                const specialAreaDropdownOption = specialAreaList?.map((specialArea) => ({
                    dropdownLabel: specialArea.parameterDtlCode,
                    tagLabel: specialArea.parameterDtlCode,
                    value: specialArea.parameterDtlCode,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        specialAreaDropdownOptions: specialAreaDropdownOption
                    }
                }))
            }
        )
        await specialIndRepo.getSpecialIndForComboBox().then(
            specialIndList => {
                const specialIndDropdownOption = specialIndList?.map((specialInd) => ({
                    dropdownLabel: specialInd.specialInd,
                    tagLabel: specialInd.specialInd,
                    value: specialInd.specialInd,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        specialIndDropdownOptions: specialIndDropdownOption
                    }
                }))
            }
        )

        await equipmentCodeIndMappingRepo.getEquipmenyCodeComboBox().then(
            equipmentCodeInds => {
                const equipmentCodeIndDropdownOption = equipmentCodeInds?.map((equipmentCodeInd) => ({
                    dropdownLabel: equipmentCodeInd.equipmentCodeInd,
                    tagLabel: equipmentCodeInd.equipmentCodeInd,
                    value: equipmentCodeInd.equipmentCodeInd,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        equipmentCodeIndDropdownOptions: equipmentCodeIndDropdownOption
                    }
                }))
            }
        )
        await shiftRepo.getShiftCodeForComboBox().then(
            shiftCodes => {
                const shiftCodeDropdownOption = shiftCodes?.map((shiftCode) => ({
                    dropdownLabel: shiftCode.shiftCode,
                    tagLabel: shiftCode.shiftCode,
                    value: shiftCode.shiftCode,
                })) ?? []

                tariffMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        shiftCodeDropdownOptions: shiftCodeDropdownOption
                    }
                }))
            }
        )

    }

    const searchAllTariffMappingList = async () => {
        /*tariffMappingDispatch(prevState => {
            return {
                ...prevState,
                currentRow: { ...EMPTY_TARIFF_MAPPING_ENTITY },
                currentEditRow: { ...EMPTY_TARIFF_MAPPING_ENTITY },
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
                updatedRows: [],
                tariffMappingList: [],
            }
        })*/
        await tariffMappingRepo.searchAllTariffMappingList().then(data => {
            if (data) {
                tariffMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        isRefreshTableData: false,                        
                        tariffMappingList: data                        
                    }
                })
            } else {
                tariffMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        isRefreshTableData: false,
                        tariffMappingList: [],
                    }
                })
            }
        })
    }

    const onRowDrag = (rows: TariffMappingEntity[]) => {
        tariffMappingDispatch(prevState => {
            const prevRows = prevState.tariffMappingList;
            const toChangedRows = rows.map((e, index) => ({ ...e, tempPriority: prevRows[index].priority })).filter(e => e.tempPriority !== e.priority);
            return ({
                ...prevState,
                tariffMappingList: rows,
                updatedRows: toChangedRows,
            })
        })
    }

    const onApply = async (updatedRows: TariffMappingEntity[]) => {

        return await tariffMappingRepo.onApply(updatedRows);
    }

    const updateSelectedRows = async (rows: TariffMappingEntity[]) => {

        tariffMappingDispatch(prevState => {
            return {
                ...prevState,
                // tariffMappingList: [...allRows],
                selectedRows: rows,
                forceRefresh: !prevState.forceRefresh
            }
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        if (fieldKey === 'chargeType') {
            tariffMappingDispatch(prevState => {
                return {
                    ...prevState,
                    currentEditRow: {
                        ...prevState.currentEditRow,
                        subChargeType: val === prevState.currentEditRow.chargeType ? prevState.currentEditRow.subChargeType : ''
                    },
                }
            })
        }
        tariffMappingDispatch(prevState => {
            if (fieldKey === 'loaLimitFrom' || fieldKey === 'loaLimitTo' || fieldKey === 'overWeightFrom' || fieldKey === 'overWeightTo') {
                var posIntReg = /^\d*(\.\d{0,2})?$/;
                if (!posIntReg.test(val)) {
                    val = prevState.currentEditRow[fieldKey];
                }
            }
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onTariffTypeChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        
        tariffMappingDispatch(prevState => {
            const tarCodeOpts = prevState.dynamicOptions.tariffCodeDropdownOptions[val];
            const tempTariffCode = tarCodeOpts && tarCodeOpts.length > 0?tarCodeOpts[0].value:null;

            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldKey]: val,
                    tariffCode:tempTariffCode
                },
            }
        })
    }

    const onRowDoubleClick = async (entity: TariffMappingEntity) => {
        tariffMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: entity,
                currentRow: entity,
                isShowEditPanel: true,
            }
        })
    }

    const onCloseEidtPanel = async () => {
        tariffMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: { ...EMPTY_TARIFF_MAPPING_ENTITY },
                isShowEditPanel: false,
                isRefreshTableData: true,
                isAdd: false,
                isEdit: false,
            }
        })
    }

    const onSave = async (currentRow: TariffMappingEntity) => {
        return tariffMappingRepo.onSave(currentRow);
    }

    const onAddClick = async () => {
        tariffMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: { ...EMPTY_TARIFF_MAPPING_ENTITY },
                isAdd: true,
                isShowEditPanel: true,
            }
        })
    }

    const onUpload = async (file: File) => {

        const arrayBuffer = await readFileAsArrayBuffer(file);
        const tariffMappingEntities = await processFileData(arrayBuffer);

        return await tariffMappingRepo.uploadData(tariffMappingEntities);
    }

    const readFileAsArrayBuffer = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => resolve(e.target?.result);
            reader.onerror = (e) => reject(e.target?.error);

            reader.readAsArrayBuffer(file);
        });
    }


    const processFileData = async (arrayBuffer: any): Promise<TariffMappingEntity[]> => {
        const byteArray = new Uint8Array(arrayBuffer);
        const decoder = new TextDecoder('utf-8');
        const content = decoder.decode(byteArray);
        const BOM = '\uFEFF';
        const contentWithoutBOM = content.startsWith(BOM) ? content.slice(1) : content;
        const lines = contentWithoutBOM.split('\n').map(line => line.trim());
        // const lines = content.split('\n').map(line => line.trim());

        const result: TariffMappingEntity[] = [];

        lines.forEach(line => {
            const obj: TariffMappingEntity = { ...EMPTY_TARIFF_MAPPING_ENTITY };
            const lineObjArr = line.split(',');
            for (let i = 0; i < tariffMappingCSVColumnMapping.length && i < lineObjArr.length; i++) {
                //Check if it is of type number
                if (tariffMappingCSVNumberColumnMapping.includes(tariffMappingCSVColumnMapping[i])) {
                    const cleanedValue = _.isEmpty(lineObjArr[i]) ? null : lineObjArr[i].replace(/^"+|"+$/g, '');
                    obj[tariffMappingCSVColumnMapping[i]] = _.isEmpty(cleanedValue) || cleanedValue === null ? null : parseFloat(cleanedValue);
                } else {
                    obj[tariffMappingCSVColumnMapping[i]] = _.isEmpty(lineObjArr[i]) ? null : lineObjArr[i];
                }
            }
            result.push(obj);
        });

        return result;
    }
    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        tariffMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: checked ? "Y" : "N",
                },
            }
        })
    }

    const onResetClick = async (isAdd: boolean) => {
        tariffMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: isAdd ? { ...EMPTY_TARIFF_MAPPING_ENTITY } : prevState.currentRow,
            }
        })
    }
    const onEditClick = async () => {
        tariffMappingDispatch(prevState => {
            return {
                ...prevState,
                isEdit: true,
            }
        })
    }

    return {
        onEditClick: onEditClick,
        onResetClick: onResetClick,
        onCheckboxChange: onCheckboxChange,
        onUpload: onUpload,
        onAddClick: onAddClick,
        onSave: onSave,
        onCloseEidtPanel: onCloseEidtPanel,
        onRowDoubleClick: onRowDoubleClick,
        onHeaderFieldChange: onHeaderFieldChange,
        updateSelectedRows: updateSelectedRows,
        onApply: onApply,
        onRowDrag: onRowDrag,
        loadDropdownOption: loadDropdownOption,
        searchAllTariffMappingList: searchAllTariffMappingList,
        onTariffTypeChange: onTariffTypeChange
    }
}